import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class MobileDetectorService {
  tabletResolutionNotificator: Subject<boolean> = new Subject<boolean>();
  mobileResolutionNotificator: Subject<boolean> = new Subject<boolean>();
  desktopResolutionNotificator: Subject<boolean> = new Subject<boolean>();
  resolve(width) {
    if (width < 1138 && width > 800) {
      this.tabletResolutionNotificator.next(true);
    } else if (width < 800) {
      this.mobileResolutionNotificator.next(true);
    } else {
      this.desktopResolutionNotificator.next(true);
    }
  }

  notifyMobileResolution() {
    return this.mobileResolutionNotificator.asObservable();
  }

  notifyDesktopResolution() {
    return this.desktopResolutionNotificator.asObservable();
  }

  notifyTabletResolution() {
    return this.tabletResolutionNotificator.asObservable();
  }
}
