import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dentist-list',
  templateUrl: './dentist-list.component.html',
  styleUrls: ['./dentist-list.component.less']
})

export class DentistListComponent {
  @Input() serviceData;
}
