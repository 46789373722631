import { Component } from '@angular/core';

@Component({
  selector: 'app-dentist',
  templateUrl: './dentist.component.html',
  styleUrls: ['./dentist.component.less']
})

export class DentistComponent {
  serviceData = [
    {
      name: 'DENTISTS.REHABILITAITON_DENTIST.NAME',
      profession: 'DENTISTS.REHABILITAITON_DENTIST.SPECIALITY',
      image: 'assets/images/medics/Lenny1.png'
    },
    {
      name: 'DENTISTS.ENDODONTICS_DENTIST_2.NAME',
      profession: 'DENTISTS.ENDODONTICS_DENTIST_2.SPECIALITY',
      image: 'assets/images/medics/Ronal1.png'
    },
    {
      name: 'DENTISTS.PEDIATRIC_DENTIST.NAME',
      profession: 'DENTISTS.PEDIATRIC_DENTIST.SPECIALITY',
      image: 'assets/images/medics/Andrea1.png'
    }];
}
