import { Component } from '@angular/core';

declare const $: any;
@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.less']
})

export class TreatmentComponent {
  isModalVisible = false;
  modalData = {
    title: '',
    image: '',
    info: '',
    details: '',
    isTreatmentsVisible: false
  };
  serviceData = [
    {
      title: 'SERVICES.ENDODONTICS.TITLE',
      description: 'SERVICES.ENDODONTICS.DETAILS',
      image: 'assets/images/treatment/conducts.png'
    },
    {
      title: 'SERVICES.REHABILITATION.TITLE',
      description: 'SERVICES.REHABILITATION.DETAILS',
      image: 'assets/images/treatment/rehabilitacion-2.png'
    },
    {
      title: 'SERVICES.PEDIATRIC_DENTIST.TITLE',
      description: 'SERVICES.PEDIATRIC_DENTIST.DETAILS',
      image: 'assets/images/treatment/odontopediatria.png'
    },
    {
      title: 'SERVICES.ORTHODONTICS.TITLE',
      description: 'SERVICES.ORTHODONTICS.DETAILS',
      image: 'assets/images/treatment/orthodontics.png'
    },
    {
      title: 'SERVICES.EXTRACTION_OF_THIRD_MOLARS.TITLE',
      description: 'SERVICES.EXTRACTION_OF_THIRD_MOLARS.DETAILS',
      image: 'assets/images/treatment/cirugia.png'
    },
    {
      title: 'SERVICES.DIAGNOSIS_AND_ASSESSMENT.TITLE',
      description: 'SERVICES.DIAGNOSIS_AND_ASSESSMENT.DETAILS',
      image: 'assets/images/treatment/endodoncia.png'
    }];

  onModalClose() {
    this.isModalVisible = false;
  }

  openModal(key) {
    this.isModalVisible = true;
    switch (key) {
      case 'SERVICES.REHABILITATION.TITLE': {
        this.modalData = {
          title: 'SERVICES.REHABILITATION.TITLE',
          image: 'assets/images/treatment/rehabilitacion-2.png',
          info: 'MODAL.REHABILITATION.DESCRIPTION',
          details: 'MODAL.REHABILITATION.DETAILS',
          isTreatmentsVisible: true
        };
        $('.modal').modal('open');
        break;
      }
      case 'SERVICES.ENDODONTICS.TITLE': {
        this.modalData = {
          title: 'SERVICES.ENDODONTICS.TITLE',
          image: 'assets/images/treatment/conducts.png',
          info: 'MODAL.ENDODONTICS.DESCRIPTION',
          details: 'MODAL.ENDODONTICS.DETAILS',
          isTreatmentsVisible: true
        };
        $('.modal').modal('open');
        break;
      }
      case 'SERVICES.ORTHODONTICS.TITLE': {
        this.modalData = {
          title: 'SERVICES.ORTHODONTICS.TITLE',
          image: 'assets/images/treatment/orthodontics.png',
          info: 'MODAL.ORTHODONTICS.DESCRIPTION',
          details: 'MODAL.ORTHODONTICS.DETAILS',
          isTreatmentsVisible: true
        };
        $('.modal').modal('open');
        break;
      }
      case 'SERVICES.EXTRACTION_OF_THIRD_MOLARS.TITLE': {
        this.modalData = {
          title: 'SERVICES.EXTRACTION_OF_THIRD_MOLARS.TITLE',
          image: 'assets/images/treatment/cirugia.png',
          info: 'MODAL.EXTRACTION_OF_THIRD_MOLARS.DESCRIPTION',
          details: 'MODAL.EXTRACTION_OF_THIRD_MOLARS.DETAILS',
          isTreatmentsVisible: true
        };
        $('.modal').modal('open');
        break;
      }
      case 'SERVICES.DIAGNOSIS_AND_ASSESSMENT.TITLE': {
        this.modalData = {
          title: 'SERVICES.DIAGNOSIS_AND_ASSESSMENT.TITLE',
          image: 'assets/images/treatment/endodoncia.png',
          info: 'MODAL.DIAGNOSIS_AND_ASSESSMENT.DESCRIPTION',
          details: 'MODAL.DIAGNOSIS_AND_ASSESSMENT.DETAILS',
          isTreatmentsVisible: false
        };
        $('.modal').modal('open');
        break;
      }
      default: {
        this.modalData = {
          title: 'SERVICES.PEDIATRIC_DENTIST.TITLE',
          image: 'assets/images/treatment/odontopediatria.png',
          info: 'MODAL.PEDIATRIC_DENTIST.DESCRIPTION',
          details: 'MODAL.PEDIATRIC_DENTIST.DETAILS',
          isTreatmentsVisible: true
        };
        $('.modal').modal('open');
        break;
      }
    }
  }
}
