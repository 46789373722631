import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from '../components/header/header.component';
import { BannerComponent } from '../components/banner/banner.component';
import { FooterComponent } from '../components/footer/footer.component';
import { MobileDetectorService } from '../services/mobile-detector.service';
import { MobileDetectorDirective } from '../directives/mobile-detector/mobile-detector.directive';
import { MapComponent } from '../components/map/map.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { TreatmentComponent } from '../components/treatment/treatment.component';
import { TreatmentCardComponent } from '../components/treatment/treatment-card/treatment-card.component';
import { TreatmentListComponent } from '../components/treatment/treatment-list/treatment-list.component';
import { TreatmentModalComponent } from '../components/treatment/treatment-modal/treatment-modal.component';
import { DentistComponent } from '../components/dentist/dentist.component';
import { DentistCardComponent } from '../components/dentist/dentist-card/dentist-card.component';
import { DentistListComponent } from '../components/dentist/dentist-list/dentist-list.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    MapComponent,
    FooterComponent,
    MobileDetectorDirective,
    ProfileComponent,
    TreatmentComponent,
    TreatmentCardComponent,
    TreatmentListComponent,
    TreatmentModalComponent,
    DentistComponent,
    DentistCardComponent,
    DentistListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC0cejjMbtDvxUqVRNmAbWXW3PyUHuvNNo'
    })
  ],
  providers: [ MobileDetectorService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }
}
