import { Component } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {

  openFacebookPage() { }
  openWhatsappPage() { }
  openInstagramPage() { }
  openWhatsappMobilePage() {
    window.open('https://wa.me/59160774859?text=Quiero%20programar%20una%20cita%20', '_blank');
  }
}
