import { Component, OnInit } from '@angular/core';
import { MobileDetectorService } from 'src/services/mobile-detector.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})
export class MapComponent implements OnInit {
  lat = -17.3872058;
  lng = -66.153499;
  zoom =  18;
  openInfoWindow = true;
  disable = false;
  isMapReady = false;
  isMobile = false;

  constructor(private mobileDetectorService: MobileDetectorService) { }

  ngOnInit() {
    this.mobileDetectorService.notifyMobileResolution().subscribe(() => {
      this.isMobile = true;
    });

    this.mobileDetectorService.notifyDesktopResolution().subscribe(() => {
      this.isMobile = false;
    });
  }
  openMap() {
    if (this.isMobile) {
      window.open('https://www.google.com/maps/search/?api=1&query=-17.387191,-66.153608', '_blank');
    }
  }
}
