import { Directive, HostListener, AfterViewInit } from '@angular/core';

import { MobileDetectorService } from '../../services/mobile-detector.service';

@Directive({
  selector: '[appMobileDetector]'
})
export class MobileDetectorDirective implements AfterViewInit {

  constructor(private mobileDetector: MobileDetectorService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileDetector.resolve(window.innerWidth);
  }

  @HostListener('window:onload', ['$event'])
  onLoad(event) {
    this.mobileDetector.resolve(window.innerWidth);
  }

  ngAfterViewInit() {
    this.onLoad('');
  }
}
