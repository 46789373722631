import {Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-treatment-modal',
  styleUrls: ['./treatment-modal.component.less'],
  templateUrl: './treatment-modal.component.html'
})
export class TreatmentModalComponent implements OnInit {
  @Input() modalTitle;
  @Input() modalImage;
  @Input() modalInfo;
  @Input() modalDetails;
  @Input() treatmentsVisible = false;
  modal;

  ngOnInit() {
    $('.modal').modal();
  }
}
