import { Component, OnInit, ChangeDetectorRef} from '@angular/core';

import { MobileDetectorService } from '../../services/mobile-detector.service';

declare const $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent implements OnInit {
  source = 'assets/images/banner-desktop.jpg';

  constructor(private mobileDetectorService: MobileDetectorService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    $(() => {
      $('.slider').slider({
        indicators: false,
        duration: 2000,
        interval: 3000,
        fullWidth: true
      });
    });
    this.mobileDetectorService.notifyMobileResolution().subscribe(() => {
      this.source = 'assets/images/banner-mobile.png';
      this.cdr.detectChanges();
    });

    this.mobileDetectorService.notifyDesktopResolution().subscribe(() => {
      this.source = 'assets/images/banner-desktop.jpg ';
      this.cdr.detectChanges();
    });
  }
}
