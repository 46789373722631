import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-treatment-card',
  templateUrl: './treatment-card.component.html',
  styleUrls: ['./treatment-card.component.less']
})

export class TreatmentCardComponent {
  @Input() title;
  @Input() description;
  @Input() image;
  @Output() openModal: EventEmitter<string> = new EventEmitter<string>();

  openDetails() {
    this.openModal.emit(this.title);
  }
}
