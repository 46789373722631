import {Component, EventEmitter, Input, Output, AfterViewInit, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef} from '@angular/core';
import { MobileDetectorService } from 'src/services/mobile-detector.service';

declare const $: any;

@Component({
  selector: 'app-treatment-list',
  templateUrl: './treatment-list.component.html',
  styleUrls: ['./treatment-list.component.less'],
})

export class TreatmentListComponent implements  OnInit, AfterViewInit {
  @Input() serviceData;
  @Input() isModalOpen = false;
  @Input() isModalVisible = false;
  @Output() openModalEmit: EventEmitter<string> = new EventEmitter<string>();
  isLarge = true;
  isSmall = true;
  slider;

  constructor(private mobileDetectorService: MobileDetectorService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.mobileDetectorService.notifyTabletResolution().subscribe( _ => {
      this.isLarge = false;
      this.isSmall = true;
    });

    this.mobileDetectorService.notifyMobileResolution().subscribe( _ => {
      this.isLarge = false;
      this.isSmall = true;
    });

    this.mobileDetectorService.notifyDesktopResolution().subscribe( _ => {
      this.isLarge = true;
      this.isSmall = false;
    });

  }

  ngAfterViewInit() {
    this.enableSliderconfiguration('#light-slider-desktop', 3);
    this.enableSliderconfiguration('#light-slider-tablet', 2);
    this.mobileDetectorService.resolve(window.innerWidth);
    this.cd.detectChanges();
  }

  openModal(name) {
    this.openModalEmit.emit(name);
  }

  private enableSliderconfiguration(id, items) {
    $(id).lightSlider({
      item: items,
      loop: false,
      keyPress: true,
      controls: true,
      pager: true,
      auto: false,
      enableTouch: true,
      enableDrag: true,
      onBeforeStart: function (el) {},
      onSliderLoad: function(el) {},
      onBeforeSlide: function (el) {},
      onAfterSlide: function (el) {},
      onBeforeNextSlide: function (el) {},
      onBeforePrevSlide: function (el) {}
  });
  }
}
