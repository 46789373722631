import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dentist-card',
  templateUrl: './dentist-card.component.html',
  styleUrls: ['./dentist-card.component.less']
})

export class DentistCardComponent {
  @Input() name;
  @Input() profession;
  @Input() image;
}
